<template>
  <div
    :style="{ height: clientHeight - 180 + 'px' }"
    style="overflow-y: scroll"
  >
    <div class="content">
      <router-link :to="{ path: '../../../call_center/serviceLook' }">
        <el-button style="padding: 6px 32px; float: left"
          >返回</el-button
        ></router-link
      >
      <div class="topData">
        <!-- <span class="c-btn-link mr10" @click="beforeDate">前一天</span> -->
        <img
          class="lefts"
          src="../../assets/images/leftpage.png"
          alt
          @click="beforeDate"
        />
        <el-date-picker
          v-model="valueDate"
          type="date"
          placeholder="选择日期"
          format="yyyy - MM - dd "
          value-format="timestamp"
          @change="changeDa"
        >
          <!--value-format="timestamp"为毫秒时间戳-->
        </el-date-picker>
        <!-- <span class="c-btn-link ml10" >后一天</span> -->
        <img
          class="rights"
          src="../../assets/images/rightpage.png"
          alt
          @click="afterDate"
        />
      </div>
      <el-table
        :data="tableData"
        style="width: 100%; margin-top: 1.5%"
        :default-sort="{ prop: 'time', order: 'descending' }"
      >
        <el-table-column prop="serviceTypeCn" label="客服类型" width="100">
        </el-table-column>
        <el-table-column prop="workNumber" label="客服工号" width="100">
        </el-table-column>
        <el-table-column prop="userName" label="用户姓名"> </el-table-column>
        <el-table-column prop="productName" label="用户设备"> </el-table-column>
        <el-table-column prop="phone" label="来电号码" width="120">
        </el-table-column>
        <el-table-column
          prop="imei"
          label="设备号码"
          :show-overflow-tooltip="true"
        ></el-table-column>
        <el-table-column prop="mainUniqueId" label="通话ID" width="160">
        </el-table-column>
        <el-table-column prop="callInDate" label="开始时间"> </el-table-column>
        <el-table-column prop="callEndDate" label="结束时间"> </el-table-column>
        <el-table-column prop="endReson" label="原因"> </el-table-column>
        <el-table-column prop="Reassignment" label="回电工单">
          <template slot-scope="scope">
            <el-button
              style="color: #606266; padding: 6px 15px"
              @click="open(scope.$index, scope.row)"
              >创建</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="bottom-left" style="margin-top: 2%">
        <p class="bottom-shu">共计{{ totalCount }}条数据</p>
      </div>
      <div class="pages" style="float: right">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-size="pageSize"
          :current-page="pageCount"
          layout="total, prev, pager, next"
          :total="totalCount"
          @pagination="getData"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
// import { api } from "../../api1/config";
import { apiShout } from "../../api1/configShout";
export default {
  data() {
    return {
      clientHeight: document.body.clientHeight,
      selDateTimes: "2020-01-21",
      currentPage1: 1,
      pageSize: 10,
      totalCount: 0,
      pageCount: 1,
      tableData: [],
      dialogVisible: false,
      serviceId: "", //关联客服id
      workOrder: null,
      centerTypeID: "",
      userNames: "",
      valueDate: "",
      flag: false,
      times: "",
    };
  },
  created() {
    this.getData();
  },
  mounted() {
    this.valueDate = new Date();
    // var date = new Date(this.valueDate);
    // var y = date.getFullYear();
    // var m = date.getMonth() + 1;
    // m = m < 10 ? "0" + m : m;
    // var d = date.getDate();
    // d = d < 10 ? "0" + d : d;
    // // var h = date.getHours();
    // // h = h < 10 ? "0" + h : h;
    // // var minute = date.getMinutes();
    // // minute = minute < 10 ? "0" + minute : minute;
    // // var s = date.getSeconds();
    // // s = s < 10 ? "0" + s : s;
    // this.valueDate = y + "-" + m + "-" + d;
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenHeight = document.body.clientHeight;
        that.clientHeight = window.screenHeight;
      })();
    };
  },
  watch: {
    clientHeight(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.clientHeight = val;
        this.timer = true;
        let that = this;
        setTimeout(function () {
          // 打印screenWidth变化的值
          console.log(that.clientHeight);
          that.timer = false;
        }, 400);
      }
    },
  },

  methods: {
    beforeDate() {
      //   console.log("时间：", this.valueDate);
      this.flag = true;
      this.valueDate = new Date(
        this.valueDate.setDate(this.valueDate.getDate() - 1)
      );
      this.getData();
    },
    afterDate() {
      this.flag = true;
      this.valueDate = new Date(
        this.valueDate.setDate(this.valueDate.getDate() + 1)
      );
      this.getData();
      //   console.log("时间222：", this.valueDate);
    },
    changeDa(val) {
      this.flag = true;
      this.valueDate = new Date(val);
      this.getData();
      //   console.log("lalala:", this.valueDate);
    },

    //列表查询
    getData() {
      if (this.flag == true) {
        var date1 = new Date(this.valueDate);
        var y1 = date1.getFullYear();
        var m1 = date1.getMonth() + 1;
        m1 = m1 < 10 ? "0" + m1 : m1;
        var d1 = date1.getDate();
        d1 = d1 < 10 ? "0" + d1 : d1;
        this.times = y1 + "-" + m1 + "-" + d1;
      } else {
        var date = new Date();
        var y = date.getFullYear();
        var m = date.getMonth() + 1;
        m = m < 10 ? "0" + m : m;
        var d = date.getDate();
        d = d < 10 ? "0" + d : d;
        this.times = y + "-" + m + "-" + d;
      }
      apiShout
        .getcallloss({
          pageSize: this.pageSize,
          pageCount: this.pageCount,
          startDate: this.times,
        })
        .then((res) => {
          console.log("客服呼损：", res.data);
          if (res.data.code == 200) {
            this.tableData = res.data.result.data;
            this.totalCount = res.data.result.page.totalCount;
            this.pageSize = res.data.result.page.pageSize;
            // this.flag = false;
          }
        });
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.getData();
    },
    //当前在第几页
    handleCurrentChange(pageCount) {
      this.pageCount = pageCount;
      this.getData();
    },
    // 创建
    open(index, row) {
      console.log(index, row, "创建工单");
      this.$confirm("是否确定创建此工单?", "确认信息", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let obj = {
            customerId: row.memberId,
            equipmentId: row.equipmentId,
            describe:
              row.userName +
              "," +
              row.callInDate +
              "致电客服，但未接通，原因为" +
              row.endReson +
              ",需要回电询问情况。",
            callPhone: row.phone,
            mainUniqueId: row.mainUniqueId,
          };
          //创建工单
          apiShout.getlossOrder(obj).then((aa) => {
            console.log("创建工单：", aa.data);
            if (aa.data.code === 200) {
              this.$message({
                type: "success",
                message: "提交成功!",
              });
            } else {
              this.$message.error(aa.data.msg);
            }
          });
          console.log("输出：");
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作！",
          });
        });
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  padding: 40px 30px 0px;
}
/deep/.el-table .warning-row {
  background: #f09c83 !important;
}
/deep/.el-dialog__headerbtn {
  top: 10px;
}
/deep/ .el-table tbody tr:hover > td {
  background-color: #f5f7fa;
}
.topData {
  float: left;
  margin-left: 30px;
  margin-top: -8px;
}
.lefts {
  width: 26px;
  margin-right: 10px;
}
.rights {
  width: 26px;
  margin-left: 10px;
}
/deep/.el-table td,
.el-table th {
  padding: 12px 0;
  min-width: 0;
  box-sizing: border-box;
  text-overflow: ellipsis;
  vertical-align: middle;
  position: relative;
  text-align: center !important;
}
/deep/.el-table .cell {
  text-align: center !important;
}
/deep/.el-table td,
.el-table th.is-leaf {
  border-bottom: none !important;
}
/deep/.el-table thead tr th.is-leaf {
  border: none;
  border-right: none;
}
/deep/.el-form-item__label {
  color: #909399;
}
</style>